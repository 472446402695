import { ErrorPageWrapper } from '@zep/components/error';
import { ErrorWidget } from '@zep/components/error/ErrorWidget';
import { getEmptyLayout } from '@zep/layout';
import { Button } from '@zep/ui';
import { getDefaultStaticProps } from '@zep/utils';
import { NextPageWithLayout } from 'next';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
const Quiz404: NextPageWithLayout = () => {
  const router = useRouter();
  const {
    t
  } = useTranslation(['common']);
  return <ErrorPageWrapper data-sentry-element="ErrorPageWrapper" data-sentry-component="Quiz404" data-sentry-source-file="404.tsx">
      <ErrorWidget.Root data-sentry-element="unknown" data-sentry-source-file="404.tsx">
        <ErrorWidget.Header data-sentry-element="unknown" data-sentry-source-file="404.tsx">
          <ErrorWidget.Image src={'/assets/error_404.png'} data-sentry-element="unknown" data-sentry-source-file="404.tsx" />
        </ErrorWidget.Header>
        <ErrorWidget.Content data-sentry-element="unknown" data-sentry-source-file="404.tsx">
          <ErrorWidget.Title data-sentry-element="unknown" data-sentry-source-file="404.tsx">
            {t('common:common.notFoundPage.title')}
          </ErrorWidget.Title>
          <ErrorWidget.Description data-sentry-element="unknown" data-sentry-source-file="404.tsx">
            {t('common:common.notFoundPage.content')}
          </ErrorWidget.Description>
        </ErrorWidget.Content>
        <ErrorWidget.Footer data-sentry-element="unknown" data-sentry-source-file="404.tsx">
          <Button size="lg" onClick={() => router.push('/landing')} data-sentry-element="Button" data-sentry-source-file="404.tsx">
            {t('common:common.notFoundPage.button')}
          </Button>
        </ErrorWidget.Footer>
      </ErrorWidget.Root>
    </ErrorPageWrapper>;
};
Quiz404.getLayout = getEmptyLayout;
export const getStaticProps = getDefaultStaticProps;
export default Quiz404;