import React, { ComponentPropsWithoutRef, ReactNode } from 'react';
import { cn } from '@zep/utils';
const BottomLogo = () => {
  return <div className={'absolute bottom-[40px]'} data-sentry-component="BottomLogo" data-sentry-source-file="ErrorPageWrapper.tsx">
      <svg width="92" height="20" viewBox="0 0 92 20" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="ErrorPageWrapper.tsx">
        <path d="M78.3021 4.53096C77.3686 4.53096 76.8934 5.1605 76.8934 6.3115V14.6908C76.8934 15.8399 77.3686 16.4713 78.3021 16.4713C79.2356 16.4713 79.7108 15.8418 79.7108 14.6908V6.3096C79.7108 5.1605 79.2169 4.52907 78.3021 4.52907V4.53096Z" fill="#6758FF" data-sentry-element="path" data-sentry-source-file="ErrorPageWrapper.tsx" />
        <path d="M74.0928 4.56699C73.105 4.56699 72.5924 5.17946 72.5924 6.3096V11.3895C72.5924 12.6505 71.8797 13.5588 70.5813 13.5588C69.283 13.5588 68.5702 12.6315 68.5702 11.3895V6.3096C68.5702 5.17946 68.0763 4.56699 67.1989 4.56699C66.3215 4.56699 65.7902 5.17946 65.7902 6.3096V11.6853C65.7902 14.2623 67.4552 16.3196 70.4915 16.3196C73.5278 16.3196 75.3743 14.2812 75.3743 11.6853V6.3096C75.3743 5.17946 75.0451 4.56699 74.0947 4.56699H74.0928Z" fill="#6758FF" data-sentry-element="path" data-sentry-source-file="ErrorPageWrapper.tsx" />
        <path d="M64.5293 10.2139C64.5293 6.6073 62.2956 4.19723 58.9375 4.19723C55.5794 4.19723 53.327 6.6073 53.327 10.2139C53.327 13.8205 55.5963 16.2305 58.9375 16.2305C62.2788 16.2305 59.7176 16.1945 60.0806 16.1376L60.7877 17.0762C61.4238 17.9049 62.0954 18.0698 62.9111 17.4081C63.7286 16.7634 63.8184 16.0276 63.1113 15.2919L62.7109 14.8501C63.854 13.8015 64.5256 12.1821 64.5256 10.212L64.5293 10.2139ZM58.9188 13.5398C57.2688 13.5398 56.1519 12.2106 56.1519 10.1968C56.1519 8.18305 57.2688 6.94293 58.9188 6.94293C60.5688 6.94293 61.7025 8.21907 61.7025 10.1968C61.7025 12.1746 60.567 13.5398 58.9188 13.5398Z" fill="#6758FF" data-sentry-element="path" data-sentry-source-file="ErrorPageWrapper.tsx" />
        <path d="M16.6051 8.3063H16.6426C16.9793 6.88415 16.8671 5.55681 16.2123 4.5139C15.3517 3.11071 13.6867 2.48496 11.7224 2.57977C10.2444 1.00592 8.44847 0.285367 6.74605 0.77838C5.36166 1.19554 4.25789 2.37119 3.56569 3.98296C1.90068 4.60871 0.665958 5.72747 0.198259 7.24443C-0.194608 8.51488 0.0111797 9.86118 0.684666 11.1316C0.142135 12.7813 0.179551 14.3552 0.92787 15.5687C1.88198 17.1047 3.75277 17.7115 5.96031 17.465C7.43824 19.0198 9.2155 19.7214 10.8992 19.2095C12.2836 18.7923 13.3874 17.6166 14.0796 16.0049C15.7446 15.3791 16.9793 14.2604 17.4283 12.7624C17.8773 11.3023 17.5405 9.72845 16.6051 8.3063Z" fill="#6758FF" data-sentry-element="path" data-sentry-source-file="ErrorPageWrapper.tsx" />
        <path fillRule="evenodd" clipRule="evenodd" d="M42.7027 4.67697C42.3585 4.67697 42.0274 4.81918 41.7842 5.07707C41.5391 5.33685 41.4025 5.68196 41.4025 6.04603V15.0814C41.4025 15.838 41.9844 16.4505 42.7027 16.4505C43.4211 16.4505 44.0029 15.838 44.0029 15.0814V12.8913H46.6726C47.3573 12.9198 48.2515 12.6922 48.9998 12.0608C49.8211 11.3687 50.3786 10.2802 50.3786 8.78414C50.3786 7.28804 49.8155 6.2091 48.9867 5.53026C48.2197 4.90072 47.3423 4.7111 46.7755 4.67887C46.7511 4.67697 46.7287 4.67697 46.7044 4.67697H42.7027ZM46.7025 10.1513H44.0029V7.41129H46.6595C46.8821 7.42836 47.1739 7.51179 47.3872 7.68814C47.5481 7.82087 47.7763 8.07686 47.7763 8.78225C47.7763 9.48763 47.5425 9.77965 47.3741 9.92186C47.1478 10.1096 46.8746 10.1608 46.7736 10.1551C46.7493 10.1551 46.7268 10.1551 46.7025 10.1551V10.1513Z" fill="#6758FF" data-sentry-element="path" data-sentry-source-file="ErrorPageWrapper.tsx" />
        <path d="M22.8068 4.82487C22.0828 4.82487 21.4973 5.42976 21.4973 6.17497C21.4973 6.92018 22.0828 7.52506 22.8068 7.52506H26.5596L21.7498 14.2642C21.458 14.6737 21.4131 15.2198 21.6376 15.673C21.8621 16.1281 22.3129 16.4145 22.8068 16.4145H29.1488C29.8728 16.4145 30.4584 15.8115 30.4584 15.0644C30.4584 14.3173 29.8728 13.7143 29.1488 13.7143H25.396L30.2058 6.97517C30.4977 6.56559 30.5426 6.01948 30.3181 5.56629C30.0936 5.1112 29.6427 4.82487 29.1488 4.82487H22.8068Z" fill="#6758FF" data-sentry-element="path" data-sentry-source-file="ErrorPageWrapper.tsx" />
        <path d="M82.3468 4.82487C81.6228 4.82487 81.0372 5.42976 81.0372 6.17497C81.0372 6.92018 81.6228 7.52506 82.3468 7.52506H86.0996L81.2898 14.2642C80.9979 14.6737 80.953 15.2198 81.1775 15.673C81.402 16.1281 81.8529 16.4145 82.3468 16.4145H88.6888C89.4128 16.4145 89.9983 15.8115 89.9983 15.0644C89.9983 14.3173 89.4128 13.7143 88.6888 13.7143H84.936L89.7458 6.97517C90.0376 6.56559 90.0825 6.01948 89.858 5.56629C89.6335 5.1112 89.1827 4.82487 88.6888 4.82487H82.3468Z" fill="#6758FF" data-sentry-element="path" data-sentry-source-file="ErrorPageWrapper.tsx" />
        <path d="M33.1205 4.78885C32.3909 4.78885 31.7212 5.40132 31.7212 6.1579V15.017C31.7212 15.7735 32.3105 16.386 33.0401 16.386H38.7105C39.4401 16.386 40.0294 15.7735 40.0294 15.017C40.0294 14.2604 39.4401 13.6479 38.7105 13.6479H34.359V11.9698H38.4037C39.1202 11.9698 39.702 11.3516 39.702 10.5874C39.702 9.82326 39.122 9.2051 38.4037 9.2051H34.359V7.52696H38.7909C39.5205 7.52696 40.1098 6.91449 40.1098 6.1579C40.1098 5.40132 39.5205 4.78885 38.7909 4.78885H33.1205Z" fill="#6758FF" data-sentry-element="path" data-sentry-source-file="ErrorPageWrapper.tsx" />
        <path d="M10.6429 14.8804C10.3155 14.8804 10.0181 14.7022 9.73745 14.3343L9.43251 13.9304C9.28284 13.9304 9.08641 13.9304 8.83759 13.9304C6.67308 13.9304 5.21948 12.3774 5.21948 10.066C5.21948 7.7545 6.67308 6.20152 8.83759 6.20152C11.0021 6.20152 12.4445 7.7545 12.4445 10.066C12.4445 11.1828 12.0965 12.1594 11.4605 12.8496L11.5278 12.9254C11.7785 13.1871 11.9001 13.4678 11.8795 13.756C11.8589 14.0556 11.6906 14.3324 11.3763 14.5808C11.1312 14.7799 10.8842 14.8804 10.6448 14.8804H10.6429ZM8.8245 8.12806C7.81053 8.12806 7.18007 8.87516 7.18007 10.0792C7.18007 11.2833 7.82549 12.093 8.8245 12.093C9.8235 12.093 10.482 11.2833 10.482 10.0792C10.482 8.87516 9.83099 8.12806 8.8245 8.12806Z" fill="white" data-sentry-element="path" data-sentry-source-file="ErrorPageWrapper.tsx" />
      </svg>
    </div>;
};
export const ErrorPageWrapper = (props: ComponentPropsWithoutRef<'div'> & {
  children: ReactNode;
}) => {
  const {
    className,
    children,
    ...rest
  } = props;
  return <div className={cn('fixed bg-gray-50 flex h-dvh w-full items-center justify-center p-md z-10 pointer-events-auto', className)} {...rest} data-sentry-component="ErrorPageWrapper" data-sentry-source-file="ErrorPageWrapper.tsx">
      {children}
      <BottomLogo data-sentry-element="BottomLogo" data-sentry-source-file="ErrorPageWrapper.tsx" />
    </div>;
};